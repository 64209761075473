import React from 'react';
import parse from 'html-react-parser';

interface IProps {
    text: string;
    caption: string;
}

const Quote: React.FC<IProps> = ({ text, caption }) => {
    const html = `<blockquote style="display: flex; margin: 0;">
                  <div style="max-width: 1em">
                    <svg style="display: block; margin-right: .55em; margin-top: .5em; max-width: 1em" width="1em" height="3em" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width=".2em" height="3em" fill="#01142F"/>
                    </svg>
                  </div>
                <span style="margin-top: 0.5em; margin-bottom: 0.5em; font-style: italic; font-weight: 600; font-family: Inter, serif" >${text}</span>
                </blockquote>
                <span style="font-style: normal; font-weight: 500 !important; margin-top: 0; margin-bottom: .5em; margin-left: 1.3em; font-family: Inter,serif; font-size: .75em; color: #818C99">${caption}</span>`;

    return parse(html) as JSX.Element;
};

export default Quote;
