import React from 'react';
import { round } from 'lodash';
import { Label, Row, Col, Progress } from 'reactstrap';

interface PollResultsProps {
    title: string;
    choices: Array<{ id: number; text: string; votes: number }>;
}

const PollResults: React.FC<PollResultsProps> = ({ title, choices }) => {
    const totalVotes = choices.reduce((acc, choice) => acc + choice.votes, 0);

    return (
        <Row>
            <Col>
                <Label for="pollChoices">{title}</Label>
                <div id="pollChoices">
                    {choices.map((choice) => {
                        const percent = round((choice.votes / totalVotes) * 100);

                        return (
                            <div key={`${choice.id}`}>
                                <div>{`${choice.text} - ${percent || 0}%`}</div>
                                <Progress value={percent || 0} />
                            </div>
                        );
                    })}
                </div>
            </Col>
        </Row>
    );
};

export default PollResults;
