import { action, reaction, makeAutoObservable } from 'mobx';
import { isNull, uniq } from 'lodash';

import { pollApi } from 'api';
import articleStore from 'store/article';

class PollStore {
    constructor() {
        makeAutoObservable(this);
        reaction(
            () => this.votedPolls,
            (votedPolls) => {
                window.localStorage.setItem('votedPolls', JSON.stringify(votedPolls));
            }
        );
    }

    votedPolls: number[] = isNull(window.localStorage.getItem('votedPolls'))
        ? []
        : JSON.parse(window.localStorage.getItem('votedPolls') as string);

    @action setVotedPoll(id: number) {
        this.votedPolls = uniq([...this.votedPolls, id]);
    }

    checkVotedPoll(id: number): boolean {
        return this.votedPolls.includes(Number(id));
    }

    makeVote(pollId: number, choices: number[]) {
        pollApi.setAnonymousChoice(pollId, choices).then(({ data }) => {
            articleStore.setPoll(data);
            this.setVotedPoll(pollId);
        });
    }
}

export default new PollStore();
