import styled from 'styled-components';

const EmbedResponsive = styled.div`
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding: 0 0 56.25% 0;
`;

export default EmbedResponsive;
