import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import { theme } from 'theme/theme';
import JournalList from 'views/JournalList';
import JournalSingle from 'views/JournalSingle';

const Journal: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Routes>
                    <Route path="journal">
                        <Route path=":journalId" element={<JournalSingle />} />
                        <Route path="list" element={<JournalList />} />
                        <Route index element={<Navigate to="./list" replace={true} />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default Journal;
