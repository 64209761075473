import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Link from '@mui/material/Link';

import * as appConst from 'app.const';
import { useRqJournalList, useRqJournalListRemove } from './queries';
import { toDDMMYYYY } from 'lib/datetime';

import brandLogo from 'assets/images/logo_rosenergoatom2.png';

const JournalList: React.FC = () => {
    //страницы
    const [page, setPage] = useState(1);
    //количество страниц
    const pageCount = useRef<number | undefined>(undefined);
    //кеш описания списка журналов
    const rqJournalList = useRqJournalList(page);
    const removeQueries = useRqJournalListRemove();
    //навигация
    const navigate = useNavigate();

    const calcPageCount = React.useCallback((x: number) => Math.ceil(x / appConst.paginationCount), []);

    //*************************************************************************************************************
    //Если текущая страница вышла за пределы общего количества страниц, то перейти на последнюю страницу.
    //Может произойти при динамическом сокращении набора возвращаемых сервером данных.
    useEffect(() => {
        if (rqJournalList.isSuccess && rqJournalList.data && page > calcPageCount(rqJournalList.data.total)) {
            setPage(calcPageCount(rqJournalList.data.total));
        }
    }, [page, rqJournalList.isSuccess, rqJournalList.data, calcPageCount]);

    //*************************************************************************************************************
    //Если динамически изменилось количество возвращенных сервером страниц, то обновить кеш для всех запросов.
    //Кроме активного, т.к. в нем правильное количество страниц
    useEffect(() => {
        const refresh = async () => {
            if (
                rqJournalList.isSuccess &&
                rqJournalList.data &&
                (pageCount.current === undefined || calcPageCount(rqJournalList.data.total) !== pageCount.current)
            ) {
                await removeQueries();
                pageCount.current = calcPageCount(rqJournalList.data.total);
            }
        };
        refresh();
    }, [rqJournalList.isSuccess, rqJournalList.data, calcPageCount, removeQueries]);

    //*************************************************************************************************************
    //Сменить страницу
    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <Container
            sx={{
                height: { xs: '93vh', sm: '100vh' },
                bgcolor: 'grey.50',
                maxWidth: '40rem',
            }}
            maxWidth={false}
            disableGutters={true}
        >
            <Box
                sx={{
                    margin: '0 0 0 0',
                    '@media (max-width: 41rem)': { margin: '0 1rem 0 1rem' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',
                    height: '100%',
                }}
            >
                {/* заголовок */}
                <Box sx={{ bgcolor: 'grey.200', mb: 2 }}>
                    <Grid container sx={{ my: 1 }}>
                        <Grid
                            item
                            xs={5}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <Box>
                                <Link
                                    href="https://www.rosenergoatom.ru/zhurnalistam/zhurnal-rosenergoatom/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Avatar
                                        alt="..."
                                        src={brandLogo}
                                        sx={{ height: '80%', width: '90%' }}
                                        variant="square"
                                    />
                                </Link>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={7}
                            sx={{
                                fontSize: { xs: 'h5.fontSize', sm: 'h4.fontSize' },
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: { xs: 'medium', sm: 'bold' },
                                color: 'primary.dark',
                                textAlign: 'center',
                            }}
                        >
                            Энергичные люди
                        </Grid>
                    </Grid>
                </Box>

                {/* индикатор загрузки */}
                {rqJournalList.isLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress sx={{ '& .MuiCircularProgress-circle': { color: 'primary.main' } }} />
                    </Box>
                )}

                {/* ошибка */}
                {rqJournalList.isError && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: 500,
                            fontSize: 'h4.fontSize',
                        }}
                    >
                        Ошибка чтения списка журналов
                    </Box>
                )}

                {/* список журналов */}
                {rqJournalList.isSuccess && rqJournalList.data && (
                    <List
                        sx={{
                            overflow: 'auto',
                            flexGrow: 1,
                        }}
                    >
                        {rqJournalList.data.rows.map((item) => {
                            const d: Date = new Date(item.publishedAt);

                            return (
                                <React.Fragment key={item.id.toString()}>
                                    {/* элемент списка - очередной журнал */}
                                    <ListItem
                                        key={item.id.toString()}
                                        alignItems="flex-start"
                                        sx={{
                                            bgcolor: 'background.paper',
                                            ':hover': {
                                                cursor: 'pointer',
                                                boxShadow: 6,
                                                borderColor: 'primary.main',
                                            },
                                            boxShadow: 2,
                                            borderWidth: '1px',
                                            borderColor: 'primary.light',
                                            borderStyle: 'solid',
                                            transition: 'border 300ms ease',
                                            borderRadius: 2,
                                            mb: 2,
                                        }}
                                        onClick={() => navigate(`../${item.id}`)}
                                    >
                                        {/* обложка журнала */}
                                        <ListItemAvatar>
                                            <Avatar
                                                alt="..."
                                                src={item.cover}
                                                sx={{ width: { xs: '5rem', sm: '7rem' }, height: '100%' }}
                                                variant="rounded"
                                            />
                                        </ListItemAvatar>

                                        {/* заголовки журнала */}
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    sx={{
                                                        fontSize: { xs: 'body1.fontSize', sm: 'h6.fontSize' },
                                                        color: 'text.primary',
                                                    }}
                                                >
                                                    {toDDMMYYYY(d)}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    sx={{
                                                        fontSize: { xs: 'body1.fontSize', sm: 'h6.fontSize' },
                                                        fontStyle: 'italic',
                                                        fontWeight: 'bold',
                                                        color: 'primary.dark',
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>
                                            }
                                            sx={{ pl: 2 }}
                                        />
                                    </ListItem>
                                </React.Fragment>
                            );
                        })}
                    </List>
                )}

                {/* подвал списка */}
                <Box>
                    <Grid container sx={{ mb: 1, mt: 2 }}>
                        {/* пустой элемент */}
                        <Grid item xs={1}></Grid>

                        {/* страницы */}
                        <Grid item xs={10} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {rqJournalList.data && rqJournalList.data.rows && (
                                <Pagination
                                    //описание isPreviousData смотри в queries.ts
                                    disabled={rqJournalList.isPreviousData}
                                    count={calcPageCount(rqJournalList.data.total)}
                                    variant="outlined"
                                    shape="rounded"
                                    page={page}
                                    onChange={handleChangePagination}
                                />
                            )}
                        </Grid>

                        {/* индикатор фоновой загрузки данных */}
                        <Grid
                            item
                            xs={1}
                            sx={{
                                typography: 'body2',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {rqJournalList.isFetching && !rqJournalList.isLoading && (
                                <CircularProgress
                                    size={20}
                                    sx={{ '& .MuiCircularProgress-circle': { color: 'primary.main' } }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default JournalList;
