import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';

import fullLogoIcon from 'assets/images/share-logo.png';
import { emailApi } from 'api';
import Loader from 'components/Loader';

const iconHeightHeader = 48; //высота иконок header, (px)
const margin = '0.5rem'; //отступы, (px)

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 40rem;
    margin: 2rem auto 6rem auto;
    @media (max-width: 41rem) {
        margin: 2rem 1rem 6rem 1rem;
    }
    p {
        margin-bottom: 1rem;
    }
    //background-color: pink;
`;

const StyledHeaderBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: ${margin};
`;

interface IConfirmEmailProps {
    token: string;
}

const ConfirmEmail: React.FC<IConfirmEmailProps> = ({ token }) => {
    //состояние загрузки
    const [loading, setLoading] = useState(false);
    //результат выполнения
    const [resultOperation, setResultOperation] = useState('');
    //запрет изменения состояния размонированного объекта
    const isMounted = useRef(false);

    //*************************************************************************************************************
    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <Layout>
            <StyledHeaderBlock>
                <img alt="..." src={fullLogoIcon} style={{ height: iconHeightHeader }} />
            </StyledHeaderBlock>
            <Card>
                <CardBody>
                    <Row className="mt-3">
                        <h4>{`Подтвердить использование email в приложении "Энергичные люди"?`}</h4>
                    </Row>
                    <div className="text-center mt-3">
                        <Button
                            className="btn-icon"
                            color="primary"
                            outline={true}
                            type="button"
                            onClick={async () => {
                                setLoading(true);
                                emailApi
                                    .confrimEmail(token)
                                    .then(() => {
                                        if (isMounted.current) setResultOperation('Email зарегистрирован');
                                    })
                                    .catch((error) => {
                                        if (isMounted.current) {
                                            const strError = error?.response?.data?.message
                                                ? error.response.data.message
                                                : error.message;
                                            setResultOperation(`Ошибка: ${strError}`);
                                        }
                                    })
                                    .finally(() => {
                                        if (isMounted.current) setLoading(false);
                                    });

                                return;
                            }}
                        >
                            <span className="h4">ПОДТВЕРДИТЬ</span>
                        </Button>
                    </div>
                    {resultOperation && (
                        <Row className="mt-5 justify-content-center">
                            <Col className="justify-content-center">
                                <h4 className="text-center">{resultOperation}</h4>
                            </Col>
                        </Row>
                    )}
                    {loading && (
                        <Row className="mt-5 d-flex justify-content-center">
                            <Loader />
                        </Row>
                    )}
                </CardBody>
            </Card>
        </Layout>
    );
};

export default ConfirmEmail;
