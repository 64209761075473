import React from 'react';
import parse from 'html-react-parser';

interface IProps {
    text: string;
}

const Paragraph: React.FC<IProps> = ({ text }) => {
    const html = `<p style="margin-top: 0.5em; margin-bottom: 0.5em;" >${text}</p>`;
    return parse(html) as JSX.Element;
};

export default Paragraph;
