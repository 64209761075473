import React from 'react';
import parse, { Element } from 'html-react-parser';

//import InnerHTML from 'components/InnerHTML';
import Iframe from 'components/Iframe';
import ArticleImg from 'components/ArticleImg';

interface IProps {
    html: string;
}

const Raw: React.FC<IProps> = ({ html }) => {
    //если есть тег script, то установить его источник
    let script: 'none' | 'unknown' | 'telegram' | 'vk' = 'none';
    if (html.indexOf('script') >= 0) {
        if (html.indexOf('VK.Widgets.Post') >= 0) script = 'vk';
        else if (html.indexOf('telegram.org/js/telegram-widget.js') >= 0) script = 'telegram';
        else script = 'unknown';
    }

    //пока запрещаем использование script
    if (script !== 'none') return null;
    // if (script === 'unknown') return null;
    // if (script === 'telegram' || script === 'vk')
    //     return <InnerHTML html={html} style={{ marginTop: '.5em', marginBottom: '.5em' }} />;

    return parse(html, {
        replace: (domNode) => {
            const typedDomNode = domNode as Element;
            const { name, attribs /*, children*/ } = typedDomNode;
            if (name === 'iframe') {
                return <Iframe src={attribs.src} />;
            }
            if (name === 'img') {
                return <ArticleImg alt="..." src={attribs.src} />;
            }

            return undefined;
        },
    }) as JSX.Element;
};

export default Raw;
