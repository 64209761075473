import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from 'App';
import { config } from 'lib/config';
import { stat } from 'lib/stat';
import { useIsMounted } from 'lib/hooks';
import { axiosInstance } from 'api';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            notifyOnChangeProps: 'tracked',
            //staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});

const AppLoader: React.FC = () => {
    //состояние загрузки
    const [loading, setLoading] = useState(true);
    //запрет изменения состояния размонированного объекта
    const isMounted = useIsMounted();

    //*************************************************************************************************************
    useEffect(() => {
        const name = process.env.REACT_APP_CONFIG_FILENAME || '/config.json';
        axios
            .get(name)
            .then(({ data }) => {
                if (isMounted.current) {
                    config.init(data);
                    axiosInstance.defaults.baseURL = config.api.getBaseUrl();
                    stat.init(config.countly_app_key, config.countly_url);
                }
            })
            .catch(() => {})
            .finally(() => {
                if (isMounted.current) setLoading(false);
            });
    }, [isMounted]);

    if (loading) return null;

    return (
        <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={true} position="bottom-right" />
        </QueryClientProvider>
    );
};

export default AppLoader;
