import React from 'react';
import parse from 'html-react-parser';

interface IProps {
    text: string;
}

const Header: React.FC<IProps> = ({ text }) => {
    const html = `<h1 style="font-weight: 600; font-size: 1.1rem; margin-top: 0.5em; margin-bottom: 0.5em;">${text}</h1>`;
    return parse(html) as JSX.Element;
};

export default Header;
