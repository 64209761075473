import { makeAutoObservable, action } from 'mobx';

import { TEditorBlockData } from 'types';
import { articleApi, TPollWithResults } from 'api';

interface IArticle {
    id: number;
    title: string;
    category: string;
    editorData: TEditorBlockData[];
    cover: string;
    author: string;
    createdAt: string;
    poll?: TPollWithResults;
}

class ArticleStore {
    constructor() {
        makeAutoObservable(this);
    }

    article: IArticle | undefined;

    pullArticle = action((path: string) => {
        articleApi.getOne(path).then(
            action(({ data }) => {
                this.article = data as IArticle;
            })
        );
    });

    setPoll = action((newPoll: TPollWithResults) => {
        if (this.article) {
            this.article.poll = newPoll;
        }
    });
}

export default new ArticleStore();
