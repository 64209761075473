import React from 'react';
import styled from 'styled-components';
import EmbedResponsive from 'components/EmbedResponsive';

const EmbedIframe = styled.iframe`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
`;

interface IProps {
    src: string;
}

const Iframe: React.FC<IProps> = ({ src }) => {
    return (
        <EmbedResponsive>
            <EmbedIframe allowFullScreen={true} src={src} frameBorder="0" />
        </EmbedResponsive>
    );
};

export default Iframe;
