import React from 'react'; //test
import { useParams, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import CircularProgress from '@mui/material/CircularProgress';

import { useRqJournal } from './queries';

import googleIcon from 'assets/images/GooglePlay.png';
import appleIcon from 'assets/images/AppStore.png';
import logoIcon from 'assets/images/share-logo.png';

const linkApple = 'https://apps.apple.com/us/app/энергичные-люди/id1489355810?l=ru&ls=1';
const linkGoogle = 'https://play.google.com/store/apps/details?id=mobile.rea.news';
const iconHeightHeader = 48; //высота иконок header, (px)

//*************************************************************************************************************
//Элемент Badge для кнопки
const StyledBadge = styled(Badge)(({ theme: tm }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: tm.palette.primary.main,
        color: tm.palette.primary.main,
        boxShadow: `0 0 0 2px ${tm.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

//*************************************************************************************************************
//Картинки google / apple
type TFooterLink = { link: string; image: string; bgcolor?: string };
const StyledFooterLink = ({ link, image, bgcolor = '' }: TFooterLink) => (
    <Grid
        item
        mx={{ xs: 0, sm: 1 }}
        px={1}
        my={0}
        py={1}
        {...(bgcolor && { bgcolor })}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 1,
            ':hover': {
                boxShadow: 6,
            },
        }}
    >
        <Link href={link} target="_blank" rel="noreferrer">
            <img alt="..." src={image} height={iconHeightHeader} />
        </Link>
    </Grid>
);

//*************************************************************************************************************
//Кнопка перехода в список журналов
type TFooterButton = { bgcolor?: string };
const StyledFooterButton = ({ bgcolor = '' }: TFooterButton) => {
    const navigate = useNavigate();

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        navigate('../list', { replace: false });
    };

    return (
        <Grid
            item
            mx={{ xs: 0, sm: 1 }}
            px={1}
            my={0}
            py={1}
            {...(bgcolor && { bgcolor })}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 1,
                ':hover': {
                    boxShadow: 6,
                },
            }}
            onClick={handleClick}
        >
            <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} variant="dot">
                <img alt="..." src={logoIcon} height={iconHeightHeader} />
            </StyledBadge>
        </Grid>
    );
};

//*************************************************************************************************************
//Просмотр журнала
//id журнала из url
const JournalSingle: React.FC = () => {
    //id журнала из url
    const { journalId } = useParams();
    //кеш описания журнала
    const rqJournal = useRqJournal(Number(journalId || 0));

    // const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'stretch',
                height: { xs: '95vh', sm: '100vh' },
                // bgcolor: 'red',
                px: { xs: 0.5, md: 2 },
            }}
            maxWidth={false}
            disableGutters={true}
        >
            {/* индикатор загрузки описания журнала */}
            {rqJournal.isLoading && (
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {<CircularProgress sx={{ '& .MuiCircularProgress-circle': { color: 'primary.main' } }} />}
                </Box>
            )}

            {/* ошибка */}
            {rqJournal.isError && (
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 500,
                        fontSize: 'h4.fontSize',
                    }}
                >
                    Журнал не найден
                </Box>
            )}

            {/* заголовок и содержание журнала */}
            {rqJournal.isSuccess && rqJournal.data && (
                <React.Fragment>
                    <Typography
                        noWrap
                        sx={{
                            /*bgcolor: 'green',*/ flexShrink: 0,
                            fontSize: 'h6.fontSize',
                            fontWeight: 'bold',
                            fontStyle: 'italic',
                            color: 'primary.main',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                        }}
                    >
                        {rqJournal.data.title}
                    </Typography>
                    <Box
                        sx={{
                            /*bgcolor: 'cyan',*/ flexGrow: 1,
                            boxShadow: 2,
                            borderWidth: '1px',
                            borderColor: 'primary.light',
                            borderStyle: 'solid',
                        }}
                    >
                        <iframe
                            id="pdf frame"
                            title="pdf frame"
                            width="100%"
                            height="100%"
                            src={`/pdfjs-2.12.313-dist/web/viewer.html?file=${rqJournal.data.content}`}
                        >
                            Ваш браузер не поддерживает встроенные фреймы!
                        </iframe>
                    </Box>
                </React.Fragment>
            )}

            {/* подвал - кнопки */}
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                sx={{ /*bgcolor: 'green',*/ flexShrink: 0, my: 1 }}
            >
                <StyledFooterLink link={linkGoogle} image={googleIcon} />
                <StyledFooterLink link={linkApple} image={appleIcon} />
                <StyledFooterButton />
            </Grid>
        </Container>
    );
};

export default JournalSingle;
