import React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { journalApi } from 'api';

//список журналов
type TJournal = {
    id: number;
    title: string;
    cover: string;
    content: string;
    publishedAt: Date;
};
type TJournalList = {
    rows: TJournal[];
    total: number;
};

//*************************************************************************************************************
//Обновить кеш для всех запросов, кроме активного
export const useRqJournalListRemove = () => {
    const queryClient = useQueryClient();
    return React.useCallback(
        async () => queryClient.removeQueries(['journals', 'list'], { active: false }),
        [queryClient]
    );
};

//*************************************************************************************************************
//список журналов
export function useRqJournalList(page: number) {
    return useQuery<TJournalList>(
        ['journals', 'list', page],
        async () => {
            const { data } = await journalApi.getList(page - 1);
            return data;
        },
        {
            staleTime: 0,
            //используется для пейджинга: начинается переход на новую страницу,
            //но при этом  isLoading !== true, т.е. индикатор загрузки не отображается
            //показываются старые данные (текущая страница)
            //когда будут получены новые данные, тогда установится признак isPreviousData = false и данные на странице обновятся
            keepPreviousData: true,
        }
    );
}
