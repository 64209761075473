import React from 'react';
import { Row, Col } from 'reactstrap';
import { observer } from 'mobx-react-lite';

import Results from 'components/Poll/Results';

interface PollProps {
    title: string;
    choices: Array<{ id: number; text: string; votes: number }>;
}

const Poll: React.FC<PollProps> = observer(({ title, choices }) => {
    return (
        <React.Fragment>
            <Row>
                <Col className="mt-3">
                    <h6>Опрос</h6>
                </Col>
            </Row>
            <Results title={title} choices={choices} />
        </React.Fragment>
    );
});

export default Poll;
