import axios from 'axios';

import * as appConst from 'app.const';
import { config as appConfig } from 'lib/config';

export const axiosInstance = axios.create();

export type TPollWithResults = {
    id: number;
    title: string;
    isAnonymous: boolean;
    isSingle: boolean;
    isVoted?: boolean;
    choices: Array<{
        id: number;
        text: string;
        votes: number;
        isMyVote?: boolean;
    }>;
};

export const articleApi = {
    getOne: (path: string) => axiosInstance.get(`/article${path}/v2`),
};

export const pollApi = {
    setAnonymousChoice: (voteId: number, choices: number[]) =>
        axiosInstance.post<TPollWithResults>(`/poll/${voteId}/vote/anonymous`, {
            choices,
        }),
};

export const emailApi = {
    confrimEmail: (token: string) => axiosInstance.post(`/confirm`, { token }),
};

const getSmiBaseUrl = () => `${appConfig.api.getSmiBaseUrl()}/smi/spa`;

export const journalApi = {
    getOne: (id: number) => axiosInstance.get(`/issue/${id}`, { baseURL: getSmiBaseUrl() }),
    getList: (page: number) =>
        axiosInstance.get(`/issue/?pageOffset=${page}&pageCount=${appConst.paginationCount}`, {
            baseURL: getSmiBaseUrl(),
        }),
};
