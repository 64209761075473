import { useQuery } from 'react-query';

import { journalApi } from 'api';

//журнал
type TJournal = {
    title: string;
    cover: string;
    content: string;
    publishedAt: Date;
};

//*************************************************************************************************************
//описание журнала
export function useRqJournal(journalId: number) {
    return useQuery<TJournal>(
        ['journals', 'detail', journalId],
        async ({ queryKey: [, , id] }) => {
            const { data } = await journalApi.getOne(id as number);

            return data;
        },
        {
            staleTime: Infinity,
        }
    );
}
