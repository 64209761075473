import React from 'react';
import { Spinner } from 'reactstrap';

const Loader: React.FC = () => {
    return (
        <Spinner color="primary" size="">
            Loading...
        </Spinner>
    );
};

export default Loader;
