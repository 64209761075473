import React from 'react';
import parse from 'html-react-parser';

import EmbedResponsive from 'components/EmbedResponsive';

interface IProps {
    src: string;
}

const Embed: React.FC<IProps> = ({ src }) => {
    const html = `<iframe src="${src}" allowFullScreen={true} src={src} frameBorder="0" style="position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;" ></iframe>`;

    return <EmbedResponsive>{parse(html)}</EmbedResponsive>;
};

export default Embed;
