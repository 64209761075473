import React from 'react';
import styled from 'styled-components';

import shortLogoIcon from 'assets/images/logo.png';
import googleIcon from 'assets/images/GooglePlay.png';
import appleIcon from 'assets/images/AppStore.png';
import { stat } from 'lib/stat';

const screenBreakpointXs = 360; //когда скрывать лого на узких экранах, (px)
const margin = 6; //отступы, (px)

const Footer = styled.div`
    display: flex;
    // flex-grow: 0;
    // flex-shrink: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${margin}px ${margin}px;
    background: rgba(230, 239, 246, 0.8);
    box-shadow: 0px 0px 3.60209px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(97.9151px);
    border-radius: 10px;
`;

//при уменьшении ширины < 360 прячем лого в нижней плашке
const Logo = styled.div`
    @media (max-width: ${screenBreakpointXs - 1}px) {
        display: none;
    }
`;

interface FooterProps {
    linkApple: string;
    linkGoogle: string;
    iconHeight: number;
}

const FooterBlock: React.FC<FooterProps> = ({ linkApple, linkGoogle, iconHeight }) => {
    return (
        <Footer>
            <Logo>
                <img
                    alt="..."
                    src={shortLogoIcon}
                    style={{
                        height: iconHeight,
                        marginRight: margin,
                    }}
                />
            </Logo>
            <a
                href={linkApple}
                target="_blank"
                rel="noreferrer"
                onClick={() => stat.addEvent({ name: 'blog_store', value: { store: 'AppStore' } })}
            >
                <img
                    alt="..."
                    src={appleIcon}
                    style={{
                        height: iconHeight,
                        marginRight: margin,
                    }}
                />
            </a>
            <a
                href={linkGoogle}
                target="_blank"
                rel="noreferrer"
                onClick={() => stat.addEvent({ name: 'blog_store', value: { store: 'GooglePlay' } })}
            >
                <img
                    alt="..."
                    src={googleIcon}
                    style={{
                        height: iconHeight,
                    }}
                />
            </a>
        </Footer>
    );
};

export default FooterBlock;
