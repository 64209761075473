import React from 'react';
import parse from 'html-react-parser';

interface IProps {
    caption: string;
    src: string;
}

const Image: React.FC<IProps> = ({ src, caption }) => {
    const html = `<div><img src="${src}" style="width: 100%;
    height: auto;" /><p style="font-style: normal; font-weight: 500; margin-top: .5em; margin-bottom: .5em; padding-left: 1em; font-family: Inter,serif; font-size: 0.75em; color: #818C99">${caption}</p></div>`;
    return parse(html) as JSX.Element;
};

export default Image;
